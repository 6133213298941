import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Mail from '../Mail';
import comcomLogo from '../../assets/Logo_trimmed.png'

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-10 mr-3">
          <img src={comcomLogo} alt="Comcom Logo" />
        </div>
        Comcom
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#expertise">
          Expertise
        </AnchorLink>
        <AnchorLink className="px-4" href="#projects">
          Projects
        </AnchorLink>
        <AnchorLink className="px-4" href="#contact">
          Contact
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <Mail />
      </div>
    </div>
  </header>
);

export default Header;
