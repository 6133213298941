import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3 justify-around">
      <div className="flex-0 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">comcom.chat Oy is a Finnish software consulting agency.</p>
      </div>
      <div className="flex-0 px-3">
        <h2 className="text-lg font-semibold">Profile</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://github.com/tuomohopia/">GitHub</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
